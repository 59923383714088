import { useLocation } from "@reach/router";
import { Spin } from "antd";
import { Link, navigate } from "gatsby";
import { map } from "lodash";
import get from "lodash.get";
import React, { useState } from "react";
import { connect } from "react-redux";
import { binanceWordImg, ftxWhiteImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

// const PAGESIZE = 10;
// markup
const NewsAndVideo = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  // const [dataSource, setDataSource] = useState([]);
  // const [page, setPage] = useState(1);
  // const [selectedData, setSelectedData] = useState({});
  // const [viewNews, setViewNews] = useState(false);
  // let user = get(props, "user.user");
  //   console.log(user);

  // useEffect(() => {
  //   let profile = get(user, "profile");
  //   if (!isEmpty(profile)) {
  //     getProfiles(1, 0, {
  //       _id: get(profile, "_id"),
  //     })
  //       .then((res) => {
  //         console.log(res);
  //         let data = get(res, "data");
  //         data = data[0];
  //         let apikey = get(data, "api");
  //         if (!isEmpty(apikey)) {
  //           navigate(routes.apiKey.to());
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // }, []);

  //   useEffect(() => {
  //     getData((page - 1) * PAGESIZE);
  //   }, [page]);

  //   useEffect(() => {
  //     // console.log("change type useEffect");
  //     getData((1 - 1) * PAGESIZE);
  //   }, [languageType]);

  const platforms = [
    {
      title: "binance",
      value: "binance",
      img: binanceWordImg,
      path: routes.apiKey.to(),
    },
    {
      title: "FTX",
      value: "ftx",
      img: ftxWhiteImg,
      path: "",
    },
  ];
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            onBack={() => {
              navigate(routes.home.to());
            }}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 选择平台 Choose Platform */}
                  {t("choosePlatform", sourceKey.api)}
                </div>
              </React.Fragment>
            }
          >
            <div className="pt-8">
              <div className="">
                {map(platforms, (item, index) => {
                  return (
                    <Link
                      to={get(item, "path")}
                      state={{ prevPath: location.pathname }}
                    >
                      <div className="m-5 py-2 rounded-2xl flex justify-start touchbox-bg-color">
                        <div className="justify-center items-center flex my-2 py-4 pl-6">
                          <img
                            src={get(item, "img")}
                            style={{ width: 168, height: 36 }}
                          />
                        </div>
                        {/* <div className="text-base text-white font-semibold text-center">
                                    FTX    
                                    </div> */}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsAndVideo);
